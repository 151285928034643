import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles.style';
import RoutePathComponent from '@app/components/common/route-path.component';
import { PathInterface } from '@app/interfaces/interfaces';
import { DeleteSvg } from '@app/assets/images/svgs/delete.svg';
import { CartDomain } from '@app/domains/cart.domain';
import { CartRequest, DeleteCart, DeleteCartItem, GetCart, UpdateCart } from '@app/api/cart.api';
import { getCurrencyPrice } from '@app/utils/utils';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { GetIpAddress } from '@app/api/ip.api';
import { PriceStatusEnum } from '@app/constants/enums/status.enum';
import { PlusSvg } from '@app/assets/images/svgs/plus.svg';

const CartPage: React.FC = () => {
  const { t } = useTranslation();
  const routePaths: PathInterface[] = [
    { path: '/', title: t('home') },
    { path: '#', title: 'Cart' },
  ];
  const ipAddress = useAppSelector((state) => state.ip);

  const [cart, setCart] = useState<CartDomain>();
  const [quantities, setQuantities] = useState<Array<{ priceId: number; quantity: number }>>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const fetchCart = useCallback(async () => {
    const resultIp = await GetIpAddress();
    const requestCart: CartRequest = {
      userId: 0,
      discountId: 0,
      ipAddress: resultIp.ip,
    };

    const result = await GetCart(requestCart, page, 12);

    setCart(result.data); // replace existing cart data to avoid duplication
    setTotalPages(result.data.totalPages);
    const q = result.data.carts.map((item) => ({ priceId: item.price.id, quantity: item.quantity }));
    setQuantities(q);
  }, [page]);

  useEffect(() => {
    fetchCart();
  }, [fetchCart, page]);

  const handleDeleteCart = () => {
    DeleteCart(cart?.id).then((result) => {
      if (result.success) {
        setCart(result.data);
      }
    });
  };

  const handleDeleteCartItem = (priceId: number) => {
    DeleteCartItem(cart?.id, priceId).then((result) => {
      if (result.success) {
        setCart(result.data);
      }
    });
  };

  const decreaseQuantity = (priceId: number) => {
    const qq = new Array<{ priceId: number; quantity: number }>();

    quantities?.map((item, index) => {
      if (item.priceId === priceId) {
        if (item.quantity > 1) {
          qq.push({ priceId: item.priceId, quantity: --item.quantity });
        } else {
          qq.push({ priceId: item.priceId, quantity: item.quantity });
        }
      } else qq.push({ priceId: item.priceId, quantity: item.quantity });
    });
    setQuantities(qq);
  };

  const increaseQuantity = (priceId: number) => {
    const qq = new Array<{ priceId: number; quantity: number }>();

    quantities?.map((item, index) => {
      if (item.priceId === priceId) qq.push({ priceId: item.priceId, quantity: ++item.quantity });
      else qq.push({ priceId: item.priceId, quantity: item.quantity });
    });
    setQuantities(qq);
  };

  const updateCart = () => {
    UpdateCart(cart?.id, quantities).then((result) => {
      if (result.success) {
        setCart(result.data.cart);
      }
    });
  };

  const handleShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <S.LayoutMaster>
      <div className={'box'}>
        <RoutePathComponent paths={routePaths} />

        {cart && cart?.carts.length > 0 ? (
          <>
            <div className={'buttons'}>
              <button onClick={handleDeleteCart}>
                <span>{t('clear-cart')}</span>
              </button>
              <Link to={'/'} className={'link'}>
                <span>{t('request-quote-bom')}</span>
              </Link>
              <Link to={'/'} className={'link'}>
                <span>{t('continue-shopping')}</span>
              </Link>
              <button onClick={updateCart}>
                <span>{t('update-cart')}</span>
              </button>
            </div>
            <div className={'items'}>
              <div className={'cart-items'}>
                {cart?.carts.map((item, index) => (
                  <div className={'cart-item'} key={index}>
                    <div
                      className={`header ${
                        item.price.stockStatus === PriceStatusEnum.BACKORDER
                          ? 'orange'
                          : item.price.stockStatus === PriceStatusEnum.INSTOCK
                          ? 'primery'
                          : item.price.stockStatus === PriceStatusEnum.OUTOFSTOCK
                          ? 'red'
                          : 'orange'
                      }`}
                    >
                      <p>{t(item.price.stockStatus)}</p>
                      <b onClick={() => handleDeleteCartItem(item.price.id)}>
                        <DeleteSvg />
                      </b>
                    </div>
                    <div className={'body'}>
                      <img src={item.price.product.thumbnail} />
                      <div className={'info'}>
                        <p>{item.price.product.title}</p>

                        <div className={'row'}>
                          <p>{t('manufacture')}</p>
                          <p>{item.price.product.brand.title}</p>
                        </div>
                        <div className={'row'}>
                          <p>{t('sku')}</p>
                          <p>{item.price.product.sku}</p>
                        </div>
                      </div>
                      <div className={'summary'}>
                        <div className={'row'}>
                          <p>{t('unit-price')}</p>
                          <p>{getCurrencyPrice(item.price.price, item.price.country.currency)}</p>
                        </div>

                        <div className={'quantity'}>
                          <span className={'pointer'} onClick={() => decreaseQuantity(item.price.id)}>
                            -
                          </span>
                          <span>{quantities?.filter((item2) => item2.priceId === item.price.id)[0].quantity ?? 0}</span>
                          <span className={'pointer'} onClick={() => increaseQuantity(item.price.id)}>
                            +
                          </span>
                        </div>

                        <div className={'row'}>
                          <p>{t('total-price')}</p>
                          <p>{getCurrencyPrice(item.price.price * item.quantity, item.price.country.currency)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {totalPages > page ? (
                  <div className={'show-more'}>
                    <button onClick={handleShowMore}><PlusSvg/> <span>{t('show-more')}</span></button>
                  </div>
                ) : null}
              </div>

              <div className={'cart-summary'}>
                <div className={'summary'}>
                  <p>{t('cart-summary')}</p>

                  <div className={'row'}>
                    <p>{t('sub-total')}</p>
                    <p>{getCurrencyPrice(cart?.totalPrice, cart?.currency)}</p>
                  </div>

                  <S.SubmitButtonCart>
                    <Link to={'/cart/request-order'}>
                      <span>{t('request-for-order')}</span>
                    </Link>
                  </S.SubmitButtonCart>
                </div>
              </div>
            </div>
          </>
        ) : (
          <S.NotFound>
            <p>{t('empty-cart')}</p>
            <Link to={'/'} className={'link'}>
              <span>{t('continue-shopping')}</span>
            </Link>
          </S.NotFound>
        )}
      </div>
    </S.LayoutMaster>
  );
};

export default CartPage;
