import { Page } from '@app/api/home.api';
import { SendInquiryCart } from '@app/api/inquiry.api';
import { FileComponent } from '@app/components/common/file/file.component';
import { InputComponent } from '@app/components/common/input/input.component';
import RoutePathComponent from '@app/components/common/route-path.component';
import { TextComponent } from '@app/components/common/text/text.component';
import { CartDomain } from '@app/domains/cart.domain';
import { PathInterface } from '@app/interfaces/interfaces';
import { Checkbox, FormControlLabel } from '@mui/material';
import Modal from 'react-modal';
import React, { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import validator from 'validator';
import * as S from './styles.style';
import { NoCartModal } from './no-cart-modal';
import { GetIpAddress } from '@app/api/ip.api';
import { CartRequest, GetCart } from '@app/api/cart.api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    borderRadius: '10px',
    zIndex: '1000',
    border: 'none',
    padding: '0px',
    marginTop: '60px',
  },
  overlay: { zIndex: 1000 },
};

export const SendInquryPage = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState<{
    id: number;
    uuid: string;
    title: string;
    slug: string;
    content: string;
  }>();

  useEffect(() => {
    Page(location.pathname.substring(1))
      .then((result) => {
        setPage(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location]);

  const routePaths: PathInterface[] = [
    { path: '/', title: t('home') },
    { path: '/send-inquiry', title: t('send-inquiry') },
  ];

  const [openModal, setOpenModal] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const handleSetEmail = (event: any) => {
    const { value } = event.target;

    setEmailError('');

    setEmail(value);

    if (!validator.isEmpty(value)) {
      if (!validator.isEmail(value)) {
        setEmailError(t('invalid-email-address'));
      }
    }
  };

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const handleSetDescription = (event: any) => {
    const { value } = event.target;
    setDescriptionError('');
    setDescription(value);
  };

  const [createAccount, setCreateAccount] = useState(false);

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>('');

  function ReCAPTCHARecaptcha(value: string | null) {
    setRecaptchaValue(value);
  }

  const [fileError, setFileError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const handleSelectFile = (value: File) => {
    setFileError('');
    setFile(value);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cart, setCart] = useState<CartDomain>();
  const fetchCart = useCallback(async () => {
    const resultIp = await GetIpAddress();
    const requestCart: CartRequest = {
      userId: 0,
      discountId: 0,
      ipAddress: resultIp.ip,
    };

    const result = await GetCart(requestCart);

    setCart(result.data); // replace existing cart data to avoid duplication
  }, []);
  useEffect(() => {
    fetchCart();
  }, [fetchCart]);
  const handleSubmitForm = () => {
    let goOn = true;
    setEmailError('');

    if (validator.isEmpty(email)) {
      setEmailError(t('required-email-address'));
      goOn = false;
    } else if (!validator.isEmail(email)) {
      setEmailError(t('invalid-email-address'));
      goOn = false;
    }

    if (!recaptchaValue) {
      toast.error(t('select-recaptcha'));
      goOn = false;
    }

    if (!file) {
      setFileError('select-file');
    }

    if (goOn) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('email', email);
      formData.append('createAccount', createAccount.toString());
      formData.append('captchaToken', recaptchaValue ?? '');
      formData.append('description', description);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append('file', file);

      if (cart?.id) {
        SendInquiryCart(cart?.id, formData)
          .then((result) => {
            if (result.success) {
              toast.success(t('inquiry-request-done'));
              location.reload();
            } else {
              toast.error(t('inquiry-request-failed'));
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error, 'error');
            setIsLoading(false);
          });
      } else {
        setOpenModal(true);
        setIsLoading(false);
      }
    }
  };
  return (
    <S.LayoutPage>
      <div className={'box-route'}>
        <RoutePathComponent paths={routePaths} />
      </div>

      <div className={'header'}>
        <h2>{t('send-your-request')}</h2>
        <p>{t('send-request-description')}</p>
      </div>

      <div className={'box'} style={{ padding: '30px' }}>
        <div className={'content'}>
          <div className={'inputs'}>
            <div className="form-group">
              <label>{t('enter-email-address')}</label>
              <div className="form-group-inputs">
                <InputComponent
                  error={emailError}
                  value={email}
                  onChange={handleSetEmail}
                  type={'email'}
                  placeholder={'enter-email'}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('create-account-email')}
                  onChange={() => setCreateAccount(!createAccount)}
                  className={'remember-me'}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t('upload-file-request-order')}</label>
              <div className="form-group-inputs">
                <FileComponent types="/*" max={2} error={fileError} onChange={handleSelectFile} />
              </div>
            </div>
            <div className="form-group">
              <label>{t('additional-description')}</label>
              <div className="form-group-inputs">
                <TextComponent value={description} onChange={handleSetDescription} />
              </div>
            </div>

            <div className={'row'}>
              
              {/* @ts-ignore */}
              <ReCAPTCHA sitekey="6Le8_fcoAAAAAEmIF6EO2EjKvNaAB5GstOQNnByg" onChange={ReCAPTCHARecaptcha} />
              <S.SubmitButton type="primary" htmlType="button" loading={isLoading} onClick={handleSubmitForm}>
                {t('send-inquiry')}
              </S.SubmitButton>
            </div>
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(!openModal)}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={true}
      >
        <NoCartModal/>
      </Modal>
    </S.LayoutPage>
  );
};
