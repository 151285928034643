import * as S from './product-card.style';
import { Link } from 'react-router-dom';
import { CompareSvg } from '@app/assets/images/svgs/compare.svg';
import { QuickViewSvg } from '@app/assets/images/svgs/quick0view.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDomain } from '@app/domains/product.domain';
import { getCurrencyPrice } from '@app/utils/utils';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { AddToCart, AddToCartRequest, CartRequest, GetCart } from '@app/api/cart.api';
import { setCart } from '@app/store/slices/cart.slice';
import { toast } from 'react-toastify';
import { readToken } from '@app/utils/local-storage.service';
import { UserAccountWishListStore } from '@app/api/user-account/wish-list.api';
import { FavoriteSvg } from '@app/assets/images/svgs/favorite.svg';

export const ProductCard: React.FC<{ product: ProductDomain; key: number }> = ({ product }) => {
  const { t } = useTranslation();

  const { brand, regularPrice } = product;

  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inWishList, setInWishList] = useState(product.inWishList);

  const handleIncreaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
    }
  };

  const ipAddress = useAppSelector((state) => state.ip);

  const handleAddToCard = () => {
    setLoading(true);
    const requestCart: CartRequest = {
      userId: 0,
      discountId: 0,
      ipAddress: ipAddress.ip,
    };

    const p = product;
    GetCart(requestCart).then((result) => {
      setLoading(true);
      setCart(result.data);

      const request: AddToCartRequest = {
        priceId: p?.regularPrice.id ?? 0,
        quantity: quantity,
        unitPrice: p?.regularPrice.price ?? 0,
        cartId: result.data ? result.data.id : 0,
        userId: 0,
        ipAddress: ipAddress.ip,
        status: p?.regularPrice.stockStatus ?? '',
      };

      AddToCart(request).then((result) => {
        if (result.success) {
          toast.success(t('add-to-cart-done'));
        } else {
          toast.error(t(result.message));
        }

        setLoading(false);
      });
    });
  };

  const handleSetWishlist = () => {
    if (readToken() !== '') {
      setLoading(true);
      UserAccountWishListStore(product?.id ?? 0)
        .then((result) => {
          if (result.success) {
            toast.success(t(result.message));
            setInWishList(result.data.inWishList);
          } else {
            alert(result.message);
            toast.error(t('add-to-wish-list-failed'));
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false);
        });
    } else {
      toast.error(t('login-account'));
    }
  };
  
  return (
    <S.Product>
      <figure>
        <Link to={`/brands/${brand.slug}`} className={'brand'} state={brand}>
          <span>{brand.title}</span>
        </Link>
        <Link to={`/products/${product.slug}/`} state={{ product: product }}>
          <img src={product.thumbnail} alt={product.title} />
        </Link>
        <div className={'product-actions'}>
          <Link to={'/'}>
            <b>
              <CompareSvg />
            </b>
            <span>{t('compare')}</span>
          </Link>
          <div className="icon-item">
            {inWishList ? (
              <div className={'fill'} onClick={handleSetWishlist}>
                <FavoriteSvg />
              </div>
            ) : (
              <div className={'stroke'} onClick={handleSetWishlist}>
                <FavoriteSvg />
              </div>
            )}
            <span>{t('wishlist')}</span>
          </div>
          <Link to={'/'}>
            <b>
              <QuickViewSvg />
            </b>
            <span>{t('quick-view')}</span>
          </Link>
        </div>
      </figure>
      <div className={'body'}>
        <Link to={`/products/${product.slug}`} state={{ product: product }} className={'product-title'}>
          <h1>{product.title}</h1>
        </Link>
        <p className={'product-description'} dangerouslySetInnerHTML={{ __html: product.shortDescription }}></p>

        <h5 className={'product-price'}>
          {getCurrencyPrice(regularPrice.price, regularPrice.country.currency)} (Budget)
        </h5>
        <h5 className={'product-status yellow'}>{t(regularPrice.stockStatus)}</h5>
      </div>
      <div className={'footer'}>
        <div className={'quantity'}>
          <span onClick={handleDecreaseQuantity} className={'pointer'}>
            -
          </span>
          <span>1</span>
          <span onClick={handleIncreaseQuantity} className={'pointer'}>
            +
          </span>
        </div>

        <div className={'add-to-card'}>
          <button onClick={handleAddToCard} type={'button'}>
            {t('add-to-card')}
          </button>
        </div>
      </div>
    </S.Product>
  );
};
