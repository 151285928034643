import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {UserAccountInquiries} from "@app/api/user-account/inquiry.api";
import {Link} from "react-router-dom";
import {InquiryData, InquiryTableColumns} from "@app/interfaces/inquiry.interface";
import moment from "moment";

const UserAccountInquiryPage: React.FC = (props) => {
    const {t} = useTranslation()
    const [inquiries, setInquiries] = useState<InquiryData[]>([]);
    const [count, setCount] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        loadData(1)
    }, [])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const loadData = (page) => {
        UserAccountInquiries(page).then(result => {
            if (result.success) {
                const listInquiries: InquiryData[] = result.data.inquiries.map((inquiry, index) => (
                    
                            {
                                id: inquiry.id,
                                index: inquiry.id,
                                code: inquiry.code,
                                status: inquiry.status,
                                version: inquiry.version,
                                totalPrice: inquiry.totalPrice.toString(),
                                createdAt: moment(inquiry.createdAt).format('Y-M-D h:i:s'),
                                user: inquiry.user ? inquiry.user.email : inquiry.email,
                                email: inquiry.email,
                                action: (<Link to={inquiry.uuid} state={inquiry}>{t('show')}</Link>),
                            }
                ));
                setInquiries(listInquiries);

                setCount(result.data.count)
            }
            setLoading(false)
        }).catch(error => {
            console.log(error, "error")
            setLoading(false)
        })
    }
    const onChange = (page: any) => {
        setLoading(true)
        setPage(page.current);
        loadData(page.current)
    };

    return (
        <S.LayoutMaster>
            <p className={'title'}>{t('inquiries')}</p>
            <S.TablesWrapper>
                <S.Table scroll={{x: 800}} columns={InquiryTableColumns(t)} dataSource={inquiries} loading={loading}
                         pagination={{pageSize: 20, current: page, total: count}}
                         onChange={(page: any) => onChange(page)}/>
            </S.TablesWrapper>
        </S.LayoutMaster>
    )
}

export default UserAccountInquiryPage;