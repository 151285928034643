export const CloseSvg: React.FC = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 8 L25 25 M8 25 L25 8"
                stroke="black" // Adjust color as needed
                strokeWidth="2" // Adjust width as needed for styling
                strokeLinecap="round"
            />
        </svg>
    )
}
