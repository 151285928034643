import { media } from '@app/assets/styles/themes/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto 0 auto;
  overflow-x: hidden;

  .flex {
    display: flex;
    position: relative;

    .nav-mobile {
      display: none;
      align-items: center;
      vertical-align: center;
      align-self: center;
      align-content: end;
      flex-direction: row-reverse;
      position: absolute;
      top: 20px;
      right: 20px;

      b {
        cursor: pointer;
        margin-left: 10px;

        svg {
          fill: var(--black);
        }
      }
    }
    
    .main{
      width: calc(100% - 300px);
    }

    @media only screen and ${media.lg} {
      .nav-mobile {
        display: flex;
      }

      .main {
        width: 100%;
      }
    }
  }
`;