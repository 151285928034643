import styled from "styled-components";
import {Typography} from "antd";

export const Input = styled(Typography.Text)`
  width: 100%;

  .icon {
    float: right;
    cursor: pointer;
    z-index: 99 !important;
    position: absolute;
    right: 8%;

    svg {
      fill: #707974;
    }
  }
`;