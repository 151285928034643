import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import * as S from './styles.style';
import SidebarLayout from '@app/components/layouts/user-account/sidebar/sidebar.layout';
import { CloseSvg } from '@app/assets/images/svgs/close.svg';
import { MenuSvg } from '@app/assets/images/svgs/menu.svg';

const UserAccountLayout: React.FC = () => {
  const [openMobileMenu, setOPpenMobileMenu] = useState(false);

  const handleOpenMobileMenu = () => {
    setOPpenMobileMenu(!openMobileMenu);
  };
  return (
    <S.Wrapper>
      <div className={'flex'}>
        <div className={'nav-mobile'}>
          <b onClick={handleOpenMobileMenu}>{openMobileMenu ? <CloseSvg /> : <MenuSvg />}</b>
        </div>
        <SidebarLayout open={openMobileMenu}/>
        <div className={'main'}>
          <Outlet />
        </div>
      </div>
    </S.Wrapper>
  );
};

export default UserAccountLayout;
