import styled from "styled-components";
import {Typography} from "antd";

export const Input = styled(Typography.Text)`
  width: 100%;

  textarea {
    width: 100%;
    min-height: 100px;
    margin-top: 20px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    outline: none;
  }

  textarea:hover {
    border: 1px solid var(--black) !important;
  }
  textarea:focus {
    border: 2px solid #007aff !important;
  }
  }
`;