import styled from "styled-components";
import {Layout} from "antd";

export const Product = styled(Layout)`
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30);
  margin: 20px;
  width: 298px;
  height: auto;
  position: relative;


  figure {
    width: 100%;
    align-items: center;
    position: relative;
    align-self: center;

    .brand {
      position: absolute;
      top: 0;
      border-radius: 8px;
      border: 1px solid var(--black);
      padding: 10px 24px;
      margin: 10px;
      background: var(--white);

      span {
        font-family: "robotoMedium" !important;
        color: var(--black);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    a, .icon-item {
      img {
        width: 298px;
        padding: 10px;
        max-height: 300px;
      }
    }


    .product-actions {
      position: absolute;
      bottom: 0;
      z-index: 1;
      background: var(--material-theme-ref-primary-primary-98, rgba(231, 255, 242, 0.50));
      width: 100%;
      display: inline-flex;
      padding: 10px 8px;
      justify-content: space-between;
      align-items: center;
      opacity: 0;

      a, .icon-item {
        display: flex;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;

        b {
          svg {
            stroke: var(--black);
          }
        }

        span {
          font-family: "robotoMedium" !important;
          color: var(--black);
          font-size: 13px;
          line-height: 16px;
          margin-left: 5px;
        }
      }

      .icon-item {
        div {
          &.stroke {
            svg {
              stroke: var(--black);
            }
          }

          &.fill {
            svg {
              fill: var(--black);
            }
          }
        }
      }
    }
  }

  .body {
      min-height: 240px;

    .product-title {
      text-decoration: none;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      h1 {
        font-family: "robotoMedium" !important;
        color: var(--black);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 10px;
      }

    }

    .product-description {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .product-price {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 10px;
    }

    .product-status {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 10px;
    }

    .yellow {
      color: var(--yellow-color);
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    height: 60px;

    .quantity {
      border-radius: 8px;
      border: 1px solid var(--black);
      padding: 10px 24px;
      align-items: center;
      height: 50px;

      span {
        color: var(--primary-color);
        width: 9.333px;
        height: 1.333px;
        font-size: 22px;
        font-family: "robotoMedium" !important;
        margin-right: 10px;
        margin-left: 10px;
      }

      .pointer {
        cursor: pointer;
      }
    }

    .add-to-card {
      button {
        background: var(--primary-color);
        color: var(--white);
        font-size: 15px;
        font-family: "robotoMedium" !important;
        border-radius: 8px;
        border: none;
        padding: 10px 24px;
        height: 50px;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 3px 3px var(--primary-color);

    figure {
      .product-actions {
        opacity: 1;
      }
    }
  }



`;