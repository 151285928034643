export enum PriceStatusEnum {
    INSTOCK = 'instock',
    BACKORDER = 'back-order',
    OUTOFSTOCK = 'out-of-stock'
}

export enum ProductsStatusEnum {
    PENDING = 'pending',
    ACTIVE = 'active',
    DECLINE = 'decline'
}