import {httpApi} from "@app/api/http.api";
import {CartDomain} from "@app/domains/cart.domain";

export interface CartResult {
    success: boolean,
    message: string,
    code: number,
    data: CartDomain
}

export interface CartUpdateResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        cart: CartDomain,
        errors: null
    }
}

export interface AddToCartRequest {
    priceId: number,
    quantity: number,
    unitPrice: number,
    cartId: number,
    userId: number,
    ipAddress: string,
    status: string,
}

export interface CartRequest {
    discountId: number,
    userId: number,
    ipAddress: string,
}

export interface UpdateCartRequest {
    priceId: number,
    quantity: number
}

export const GetCart = (request: CartRequest, page?: number, limit = 12): Promise<CartResult> =>
    httpApi.post<CartResult>('cart/', request, {
        params: {
            page,
            limit
        }
    }).then(({ data }) => data);

export const AddToCart = (request: AddToCartRequest): Promise<CartResult> =>
    httpApi.post<CartResult>('cart/add-to-cart/', request).then(({data}) => data);

export const DeleteCart = (id: number | undefined): Promise<CartResult> =>
    httpApi.delete<CartResult>('cart/delete-cart/' + id).then(({data}) => data);

export const DeleteCartItem = (id: number | undefined, priceId: number): Promise<CartResult> =>
    httpApi.delete<CartResult>('cart/delete-cart-item/' + id + '/' + priceId).then(({data}) => data);

export const UpdateCart = (id: number | undefined, request: Array<{
    priceId: number;
    quantity: number
}> | undefined): Promise<CartUpdateResult> =>
    httpApi.post<CartUpdateResult>('cart/update-cart/' + id, request).then(({data}) => data);
