import { media } from '@app/assets/styles/themes/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 250px;
  background: var(--primary-color);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 100vh;
  display: block;
  z-index: 1000;
  overflow-y: scroll;

  @media only screen and ${media.lg} {
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: .5s;

    &.open {
      display: block;
      transform: translateX(0);
    }
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin: 0 auto 0 auto;
    width: 100%;
    align-content: center;
    padding: 20px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 1000px;
      align-self: center;
      object-fit: cover;
    }

    p {
      color: var(--black);
      text-align: center;
      font-family: "robotoMedium" !important;
      line-height: 24px;
      font-size: 14px;
      padding: 10px;
    }
  }

  .items {
    position: absolute;
    height: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {

      top: 20%;
      width: 250px;

      ul {

        li {
          margin-right: 10px;
          margin-left: 10px;
          cursor: pointer;
          border-radius: 10px;
          transition: 0.3s;
          align-items: center;
          margin-bottom: 5px;

          a {
            padding: 10px;
            text-decoration: none;
            display: flex;
            justify-content: space-between;

            p {
              color: var(--white);
              font-family: "robotoMedium" !important;
              line-height: 24px;
              font-size: 14px;
              padding: 10px;
              width: 100%;
              text-align: left;
            }

            .stroke svg {
              stroke: var(--white);
              margin-top: 13px;
            }

            .fill svg {
              fill: var(--white);
              margin-top: 13px;
            }
          }

          &:hover {
            background: var(--light-gray);

            a {
              p {
                color: var(--primary-color);
              }

              .stroke svg {
                stroke: var(--primary-color);
              }

              .fill svg {
                fill: var(--primary-color);
              }
            }
          }

          &.active {
            background: var(--light-gray);

            a {
              p {
                color: var(--primary-color);
              }

              .stroke svg {
                stroke: var(--primary-color);
              }

              .fill svg {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }

    .bottom {

      bottom: 20%;
      width: 250px;

      ul {

        li {
          margin-right: 10px;
          margin-left: 10px;
          cursor: pointer;
          border-radius: 10px;
          transition: 0.3s;
          align-items: center;
          margin-bottom: 5px;

          a {
            padding: 10px;
            text-decoration: none;
            display: flex;
            justify-content: space-between;

            p {
              color: var(--white);
              font-family: "robotoMedium" !important;
              line-height: 24px;
              font-size: 14px;
              padding: 10px;
              width: 100%;
              text-align: left;
            }

            .stroke svg {
              stroke: var(--white);
              margin-top: 13px;
            }

            .fill svg {
              fill: var(--white);
              margin-top: 13px;
            }
          }

          &:hover {
            background: var(--light-gray);

            a {
              p {
                color: var(--primary-color);
              }

              .stroke svg {
                stroke: var(--primary-color);
              }

              .fill svg {
                fill: var(--primary-color);
              }
            }
          }

          &.active {
            background: var(--light-gray);

            a {
              p {
                color: var(--primary-color);
              }

              .stroke svg {
                stroke: var(--primary-color);
              }

              .fill svg {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }
`;