import useEmblaCarousel from 'embla-carousel-react';
import { useEffect, useState, useCallback, PropsWithChildren } from 'react';
import type { EmblaOptionsType } from 'embla-carousel'; // Import options from core package

// 1. Define the props
type Props = { options?: EmblaOptionsType } & PropsWithChildren<any>;

const ProductSlider = ({ children, options }: Props) => {
  // 2. Initialize EmblaCarousel using the custom hook
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'start',
    ...options,
  });

  const [isLargerScreen, setIsLargerScreen] = useState(false);

  // Update screen size state based on window width
  const handleResize = () => {
    setIsLargerScreen(window.innerWidth >= 1097); // 1024px for larger screen breakpoint
  };

  useEffect(() => {
    // Check initial screen size
    handleResize();
    // Add window resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll to the next slide
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // Scroll to the previous slide
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  return (
    <div className="product-slider">
      {isLargerScreen && (
        <div className="slider-btns">
          <button className="slider-btn prev-btn" onClick={scrollPrev}>
            ◀
          </button>
          <button className="slider-btn next-btn" onClick={scrollNext}>
            ▶
          </button>
        </div>
      )}
      <div ref={emblaRef} className="embla-container slider">
        <div style={{ display: 'flex', gap: 10 }}>{children}</div>
      </div>
    </div>
  );
};

export default ProductSlider;
