import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {InputComponent} from "@app/components/common/input/input.component";
import {UserDomain} from "@app/domains/user.domain";
import {persistUser, readUser} from "@app/utils/local-storage.service";
import validator from "validator";
import {FileComponent} from "@app/components/common/file/file.component";
import {
    ChangePasswordRequest,
    UserAccountChangePassword,
    UserAccountUpdateProfile
} from "@app/api/user-account/profile.api";
import {toast} from "react-toastify";
import {EyeSvg} from "@app/assets/images/svgs/eye.svg";

const ProfilePage: React.FC = (props) => {
    const {t} = useTranslation()

    const [loading, setLoading] = useState<boolean>(false)
    const [user, setUser] = useState<UserDomain | null>()

    const [firstName, setFirstName] = useState<string>("")
    const [firstNameError, setFirstNameError] = useState<string>("")
    const handleSetFirstName = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setFirstNameError(t('first-name-required'))
        } else {
            setFirstNameError('')
        }
        setFirstName(target.target.value)
    }

    const [lastName, setLastName] = useState<string>("")
    const [lastNameError, setLastNameError] = useState<string>("")
    const handleSetLastName = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setLastNameError(t('last-name-required'))
        } else {
            setLastNameError('')
        }
        setLastName(target.target.value)
    }

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState<string>("")
    const handleSetEmail = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setEmailError(t('email-required'))
        } else {
            if (!validator.isEmail(target.target.value)) {
                setEmailError(t('invalid-email'))
            } else {
                setEmailError('')
            }
        }
        setEmail(target.target.value)
    }

    const [mobile, setMobile] = useState<string>("")
    const [mobileError, setMobileError] = useState<string>("")
    const handleSetMobile = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setMobileError(t('mobile-required'))
        } else {
            setMobileError('')
        }
        setMobile(target.target.value)
    }

    const [avatar, setAvatar] = useState<File | null>(null)
    const [avatarError, setAvatarError] = useState<string>('')
    const handleSelectAvatar = (value: File) => {
        setAvatarError('avatar-not-valid')
        setAvatar(value)
    }


    useEffect(() => {
        setUser(readUser())

        const user = readUser()

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setFirstName(user?.firstName)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLastName(user?.lastName)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setEmail(user?.email)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setMobile((user?.mobile)?.toString())
    }, [])

    const handeUpdateProfile = () => {
        let goOn = true

        if (validator.isEmpty(firstName)) {
            setFirstNameError(t('first-name-required'))
            goOn = false
        }

        if (validator.isEmpty(lastName)) {
            setLastNameError(t('last-name-required'))
            goOn = false
        }

        if (validator.isEmpty(email)) {
            setEmailError(t('email-required'))
            goOn = false
        } else {
            if (!validator.isEmail(email)) {
                setEmailError(t('invalid-email'))
                goOn = false
            }
        }

        if (validator.isEmpty(mobile)) {
            setMobileError(t('mobile-required'))
            goOn = false
        }

        if (goOn) {
            setLoading(true)

            const formData = new FormData()
            formData.append('firstName', firstName)
            formData.append('lastName', lastName)
            formData.append('email', email)
            formData.append('mobile', mobile)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formData.append('avatar', avatar)

            UserAccountUpdateProfile(formData).then(result => {
                if (result.success) {
                    toast.success(t('update-profile-done'))
                    persistUser(result.data.user)
                    location.reload();
                } else {
                    toast.error(t('update-profile-failed'))
                }
                setLoading(false)
            }).catch(error => {
                console.log(error, "error")
                setLoading(false)
            })
        }
    }


    const [currentPassword, setCurrentPassword] = useState<string>("")
    const [currentPasswordError, setCurrentPasswordError] = useState<string>("")
    const handleSetCurrentPassword = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setCurrentPasswordError(t('current-password-required'))
        } else {
            setCurrentPasswordError('')
        }
        setCurrentPassword(target.target.value)
    }

    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
    const handleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword)
    }


    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordError, setNewPasswordError] = useState<string>("")
    const handleSetNewPassword = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setNewPasswordError(t('new-password-required'))
        } else {
            setNewPasswordError('')
        }
        setNewPassword(target.target.value)
    }

    const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
    const handleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const [reNewPassword, setReNewPassword] = useState<string>("")
    const [reNewPasswordError, setReNewPasswordError] = useState<string>("")
    const handleSetReNewPassword = (target: any) => {
        if (validator.isEmpty(target.target.value)) {
            setReNewPasswordError(t('re-new-password-required'))
        } else {
            setReNewPasswordError('')
        }
        setReNewPassword(target.target.value)
    }

    const [showReNewPassword, setShowReNewPassword] = useState<boolean>(false)
    const handleShowReNewPassword = () => {
        setShowReNewPassword(!showReNewPassword)
    }

    const handelChangePassword = () => {
        setNewPasswordError('')
        setCurrentPasswordError('')
        setReNewPasswordError('')
        let goOn = true

        if (validator.isEmpty(currentPassword)) {
            setCurrentPasswordError(t('current-password-required'))
            goOn = false;
        }

        if (validator.isEmpty(newPassword)) {
            setNewPasswordError(t('new-password-required'))
            goOn = false;
        }

        if (validator.isEmpty(newPassword)) {
            setReNewPasswordError(t('re-new-password-required'))
            goOn = false;
        }

        if (newPassword && reNewPassword) {
            if (newPassword !== reNewPassword) {
                setNewPasswordError(t('password-not-match'))
                goOn = false;
            }
        }

        if (goOn) {
            setLoading(true)
            const request: ChangePasswordRequest = {
                currentPassword: currentPassword,
                newPassword: newPassword,
                reNewPassword: reNewPassword
            }
            UserAccountChangePassword(request).then(result => {
                setLoading(false)
                if (result.success) {
                    toast.success(t('change-password-done'))
                    setCurrentPassword('')
                    setNewPassword('')
                    setReNewPassword('')
                } else {
                    if(result.data && result.data.errors) {
                        if(result.data.errors.currentPassword) {
                            setCurrentPasswordError(t(result.data.errors.currentPassword))
                        }
                        if(result.data.errors.newPassword) {
                            setNewPasswordError(t(result.data.errors.newPassword))
                        }
                        if(result.data.errors.reNewPassword) {
                            setReNewPasswordError(t(result.data.errors.reNewPassword))
                        }
                    }
                }
            }).catch(error => {
                setLoading(false)
            })
        }
    }

    return (
        <S.LayoutMaster>
            <p className={'title'}>{t('user-profile')}</p>

            <div className={'box'}>
                <div className={'row'}>
                    <InputComponent error={firstNameError} value={firstName} onChange={handleSetFirstName} type={'text'}
                                    placeholder={'enter-first-name'}/>
                    <InputComponent error={lastNameError} value={lastName} onChange={handleSetLastName} type={'text'}
                                    placeholder={'enter-last-name'}/>
                </div>
                <div className={'row'}>
                    <InputComponent error={emailError} value={email} onChange={handleSetEmail} type={'email'}
                                    placeholder={'enter-email'}/>
                    <InputComponent error={mobileError} value={mobile} onChange={handleSetMobile} type={'tel'}
                                    placeholder={'enter-mobile'}/>
                </div>
                <FileComponent error={avatarError} types={'image/*'} max={3000} onChange={handleSelectAvatar}/>

                <S.SubmitButton type="primary" htmlType="button"
                                loading={loading} onClick={handeUpdateProfile}>
                    {t('update-profile')}
                </S.SubmitButton>
            </div>

            <div className={'box'} style={{marginTop: '30px'}}>
                <div className={'row'}>
                    <InputComponent error={currentPasswordError} value={currentPassword}
                                    onChange={handleSetCurrentPassword} type={'password'}
                                    placeholder={'current-password'}
                                    svg={<EyeSvg/>} onHandleSvg={handleShowCurrentPassword}
                                    showPassword={showCurrentPassword}/>
                </div>
                <div className={'row'}>
                    <InputComponent error={newPasswordError} value={newPassword} onChange={handleSetNewPassword}
                                    type={'password'}
                                    svg={<EyeSvg/>} onHandleSvg={handleShowNewPassword}
                                    showPassword={showNewPassword}
                                    placeholder={'new-password'}/>
                    <InputComponent error={reNewPasswordError} value={reNewPassword} onChange={handleSetReNewPassword}
                                    type={'password'}
                                    svg={<EyeSvg/>} onHandleSvg={handleShowReNewPassword}
                                    showPassword={showReNewPassword}
                                    placeholder={'re-new-password'}/>
                </div>

                <S.SubmitButton type="primary" htmlType="button"
                                loading={loading} onClick={handelChangePassword}>
                    {t('change-password')}
                </S.SubmitButton>
            </div>
        </S.LayoutMaster>
    )
}

export default ProfilePage;