import styled from 'styled-components';
import { Layout } from 'antd';
import { media } from '@app/assets/styles/themes/constants';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }

    @media only screen and ${media.lg} {
      .row {
        flex-direction: column;
      }
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      margin: 10px auto 10px auto;
    }
  }
`;

export const Thumbnail = styled(Layout)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 33.33%;

  @media only screen and ${media.lg} {
    width: 100%;
  }

  .icons {
    display: table;
    align-self: center;
    align-items: center;
    margin: 10px;

    div {
      cursor: pointer;

      &.stroke {
        svg {
          stroke: var(--black);
          margin-bottom: 30px;
        }
      }

      &.fill {
        svg {
          fill: var(--black);
          margin-bottom: 30px;
        }
      }

      &.all {
        svg {
          fill: var(--black);
          stroke: var(--black);
          margin-bottom: 30px;
        }
      }
    }
  }
  .image {
    width: calc(100% - 55px);
    height: auto;
    margin: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const Info = styled(Layout)`
  width: 33.33%;

  @media only screen and ${media.lg} {
    width: 100%;
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    padding: 6px 16px;
    gap: 8px;
    margin-right: 10px;

    a {
      border: 1px solid var(--black);
      padding: 10px;
      font-family: 'robotoRegular' !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: var(--black);
      border-radius: 8px;
      text-decoration: none;
      text-wrap: nowrap;
    }
  }

  .product-title {
    font-family: 'robotoMedium' !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: var(--black);
    margin-top: 10px;
  }

  .product-short-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: 'robotoRegular' !important;
    color: var(--black);
    margin-top: 30px;
  }

  .short-info {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;

    p {
      font-family: 'robotoMedium' !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .alert {
    display: flex;
    margin-top: 10px;
    font-family: 'robotoMedium' !important;
    color: var(--red-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    b {
      svg {
        stroke: var(--red-color);
        margin-right: 5px;
      }
    }
  }
`;

export const Basket = styled(Layout)`
  margin-left: 20px;
  margin-right: 20px;
  width: 33.33%;

  @media only screen and ${media.lg} {
    width: 100%;
  }

  @media only screen and ${media.md} {
    width: calc(100% - 40px);
  }

  .alert {
    background: var(--red-light-color);
    border-radius: 8px;
    padding: 10px 24px;

    p {
      font-family: 'robotoMedium' !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--black);
    }

    strong {
      font-family: 'robotoMedium' !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--black);
    }
  }

  .seller {
    background: var(--white);
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;

    .seller-row {
      width: 100%;

      .col {
        width: 100%;
      }

      @media only screen and ${media.lg} {
        display: flex;
        justify-content: space-between;

        .col {
          width: 50%;
        }
      }

      @media only screen and ${media.sm} {
        display: block;

        .col {
          width: 100%;
        }
      }
    }

    .title {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      font-family: 'robotoRegular' !important;
      color: var(--black);
    }

    .logo {
      width: 87.83px;
      height: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;

      .add-to-card {
        border-radius: 8px;
        border: 1px solid var(--black);
        padding: 10px 24px;
        align-items: center;
        height: 50px;

        span {
          color: var(--primary-color);
          width: 9.333px;
          height: 1.333px;
          font-size: 22px;
          font-family: 'robotoMedium' !important;
          margin-right: 10px;
          margin-left: 10px;
        }

        .pointer {
          cursor: pointer;
        }
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-self: center;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          font-family: 'robotoRegular' !important;
          color: var(--black);
        }

        strong {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          font-family: 'robotoMedium' !important;
          color: var(--black);
          margin-left: 10px;
        }
      }
    }

    .order-row {
      width: 100%;
      align-self: center;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;

      .order {
        border-radius: 10px;
        background: var(--primary-color);
        padding: 15px;
        border: none;
        width: 70%;
        cursor: pointer;
        margin: 0 auto 0 auto;

        span {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          font-family: 'robotoMedium' !important;
          color: var(--white);
        }
      }
    }

    .alert-row {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 10px;
      width: 100%;

      @media only screen and ${media.lg} {
        width: 70%;
      }
      
      @media only screen and ${media.sm} {
        width: 100%;
      }

      .p-red {
        display: flex;
        cursor: pointer;

        b svg {
          stroke: var(--red-color);
        }

        p {
          color: var(--red-color);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          font-family: 'robotoRegular' !important;
          margin-left: 5px;
        }
      }

      .p-black {
        display: flex;
        cursor: pointer;

        b svg {
          stroke: var(--black);
        }

        p {
          color: var(--black);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          font-family: 'robotoRegular' !important;
          margin-left: 5px;
        }
      }
    }
  }
`;

export const Tabs = styled(Layout)`
  background: var(--lighter-gray-color);
  padding: 10px 24px;
  overflow: scroll;

  .box {
    width: 80%;
    margin: 0 auto 0 auto;
    display: flex;

    .item {
      width: auto;
      cursor: pointer;
      transition: 0.3s;
      border: 3px solid var(--white);
      text-wrap: nowrap;

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        font-family: 'robotoMedium' !important;
        color: var(--black);
        text-align: center;
        padding: 10px;
      }

      &.active {
        border-bottom: 3px solid var(--primary-color);
      }
    }
  }
`;

export const TabView = styled(Layout)`
  margin-top: 40px;

  .title {
    display: flex;

    .green-box {
      width: 14%;
      height: 48px;
      flex-shrink: 0;
      background: var(--primary-color);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    p {
      display: flex;
      color: var(--black);
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      margin-left: -35px;
      height: 40px;
      align-self: center;
      font-family: 'robotoMedium' !important;
    }
  }

  .title-2 {
    display: flex;

    .green-box {
      width: 40%;
      height: 48px;
      flex-shrink: 0;
      background: var(--primary-color);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    p {
      display: flex;
      color: var(--black);
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      height: 40px;
      align-self: center;
      margin-left: 20px;
      text-align: center;
      font-family: 'robotoMedium' !important;
    }
  }

  .title-3 {
    display: flex;
    justify-content: space-between;

    .row {
      display: flex;
      width: 80%;

      .green-box {
        width: 14%;
        height: 48px;
        flex-shrink: 0;
        background: var(--primary-color);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      p {
        display: flex;
        color: var(--black);
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        margin-left: -35px;
        height: 40px;
        align-self: center;
        font-family: 'robotoMedium' !important;
      }

      @media only screen and ${media.sm} {
        .green-box {
          width: 25%;
        }
      }
    }

    .add-comment {
      background: var(--primary-color);
      border: none;
      border-radius: 8px;
      padding: 10px 24px;
      position: relative;
      right: 0;
      margin-right: 10%;
      cursor: pointer;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: space-between;

      b svg {
        fill: var(--white);
        margin-right: 5px;
        align-items: center;
        align-self: center;
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--white);
        font-family: 'robotoMedium' !important;
        text-wrap: nowrap;
      }
    }
  }

  .box {
    width: 80%;
    margin: 30px auto 0 auto;

    strong {
      font-family: 'robotoMedium' !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    p {
      font-family: 'robotoRegular' !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    ul li {
      font-family: 'robotoRegular' !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .row {
      display: flex;

      &.gallery {
        align-items: center;
      }

      .file {
        display: flex;
        text-decoration: none;
        align-items: center;
        margin: 5px;

        b {
          svg {
            margin-right: 5px;
          }
        }

        span {
          font-family: 'robotoRegular' !important;
          color: var(--black);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      &.files-section {
        width: 400px;
        margin: 10px 0;
        flex-direction: row !important;
        justify-content: flex-start;
        gap: 50px;
        flex-wrap: wrap;

        @media only screen and ${media.sm} {
          width: 100%;
        }
      }

      .files {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        padding: 10px 15px; /* Padding around each file item */
        border-radius: 8px; /* Rounded corners */
        transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effect */

        &:hover {
          transform: translateY(-2px); /* Slight lift on hover */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
        }

        b {
          display: flex; /* Ensures icon is flexed and aligned */
          margin-right: 10px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        span {
          font-family: 'robotoRegular', sans-serif !important;
          color: var(--black);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          white-space: nowrap; /* Prevents long titles from wrapping */
          text-overflow: ellipsis; /* Adds "..." for overflow */
          overflow: hidden; /* Ensures text stays within bounds */
          max-width: 150px; /* Optional: Set max width for consistency */
        }
      }
    }

    .product-slider {
      position: relative;

      .slider-btns {
        position: absolute;
        right: 0;
        top: 0;

        .slider-btn {
          background: var(--porpule);
          color: var(--porpule);
          border: 2px solid var(--porpule);
          border-radius: 5px;
          padding: 5px;
          margin-left: 30px;
          cursor: pointer;
        }

        .prev-btn {
          background: transparent;
        }

        .next-btn {
          color: white;
        }
      }

      .slider {
        padding-top: 50px;
      }
    }
  }

  .box-table {
    width: 60%;
    margin: 30px auto 0 auto;

    table {
      width: 100%;

      tbody {
        padding: 10px 24px;

        tr {
          padding: 10px 24px;
          background: #f8faf6;

          &.green {
            background: rgba(231, 255, 242, 0.5);
          }

          td {
            color: var(--black);
            font-family: 'robotoRegular' !important;
            padding: 13px 0px 11px 16px;
            
            div {
              display: inline;
              font-family: inherit;
              font-size: inherit;
              font-weight: 500;
            }
          }

          td:nth-of-type(2n) {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          td:last-child {
            color: var(--black);
            font-family: 'robotoRegular' !important;
            padding: 13px 11px 0px 16px;
          }
        }
      }
    }
  }

  .box-comments {
    width: 80%;
    margin: 30px auto 0 auto;

    .show-more {
      display: flex;
      text-decoration: none;
      align-self: center;
      padding: 10px 24px;
      align-items: center;
      align-content: center;

      b {
        margin-top: 5px;
        margin-right: 10px;

        svg {
          fill: var(--primary-color);
        }
      }

      span {
        margin-top: -5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--primary-color);
        font-family: 'robotoRegular' !important;
      }
    }
  }
`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  width: 70%;
  cursor: pointer;
  margin: 0 auto 0 auto;

  @media only screen and ${media.lg} {
    width: 100%;
  }

  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: 'robotoMedium' !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;
