import React, { useEffect, useState } from "react";
import RoutePathComponent from "@app/components/common/route-path.component";
import * as S from './styles.style';
import { useTranslation } from "react-i18next";
import { PathInterface } from "@app/interfaces/interfaces";
import { Link } from "react-router-dom";
import { ProductCard } from "@app/components/common/product-card/produt-cart.component";
import { ArrowRightSvg } from "@app/assets/images/svgs/arrow-right.svg";
import { InquiryLineSvg } from "@app/assets/images/svgs/inquiry-line.svg";
import ProductSlider from "@app/components/common/product-slider";
import { HomeProducts } from "@app/api/home.api";
import { ProductDomain } from "@app/domains/product.domain";
import { CategoryDomain } from "@app/domains/category.domain";
import { BrandDomain } from "@app/domains/brand.domain";
import { SliderDomain } from "@app/domains/slider.domain";

const HomePage: React.FC = () => {
    const { t } = useTranslation()
    const routePaths: PathInterface[] = [
        { path: "/", title: t("home") }
    ]

    const [newProducts, setNewProducts] = useState<Array<ProductDomain>>([])
    const [hotProducts, setHotProducts] = useState<Array<ProductDomain>>([])
    const [categories, setCategories] = useState<Array<CategoryDomain>>([])
    const [brands, setBrands] = useState<Array<BrandDomain>>([])
    const [sliders, setSliders] = useState<Array<SliderDomain>>([])
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const timeoutRef = React.useRef(null);

    useEffect(() => {
        HomeProducts().then(result => {
            const { data } = result

            setNewProducts(data.newProducts)
            setHotProducts(data.hotProducts)
            setCategories(data.categories)
            setBrands(data.brands)
            setSliders(data.sliders)
        })
    }, [])

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timeoutRef.current = setTimeout(
            () =>
                setCurrentSlide((prevIndex) =>
                    prevIndex === sliders.length - 1 ? 0 : prevIndex + 1
                ),
            5000
        );

        return () => {
            resetTimeout();
        };
    }, [currentSlide]);

    return (
        <S.LayoutMaster>
            <div className={'box'}>
                <RoutePathComponent paths={routePaths} />

                <S.Slider>
                    <div className={'col-1'}>
                        <h3>{t('smart-products')}</h3>
                        <p className={'green'}>{t('welcome')}</p>
                        <p>{t('home-description')}</p>
                    </div>

                    <div className={'col-2'}>
                        {sliders.map((slide, inx) => (
                            <div
                                className={inx === currentSlide ? "currentSlide active" : "currentSlide"}
                                key={inx}
                            >
                                {inx === currentSlide && <img src={slide.thumbnail} className="image" />}
                            </div>
                        ))}
                    </div>
                </S.Slider>

                <S.Category>
                    <h3>{t('smart-products-categories')}</h3>
                    <p>{t('smart-products-categories-description')}</p>
                </S.Category>
                <S.Categories>
                    <div className="categories-container">
                        {categories.map((category, index) => (
                            <Link to={`/product-category/${category.slug}`} state={category} key={index}>
                                <a>
                                    <img src={category.thumbnail} alt={category.title} />
                                    <p>{category.title}</p>
                                </a>
                            </Link>
                        ))}
                    </div>
                </S.Categories>

                <S.Brands>

                    <div className={'green-box'}></div>
                    <p className={'brand-title'}>{t('brands')} <small>{t('we-support')}</small></p>

                    <div className={'brands'}>
                        {brands.map((brand, index) => (
                            <Link to={`/brands/${brand.slug}`} state={brand} key={index}>
                                <a><img src={brand.thumbnail} alt={brand.title} /></a>
                            </Link>
                        ))}
                    </div>
                </S.Brands>
            </div>

            <S.HotProducts>
                <div className={'title'}><p>{t('hot-products')}</p></div>

                <div className={'slider'}>
                    <ProductSlider options={{ align: 'center' }}>
                        {hotProducts.map((product, index) => (
                            <ProductCard product={product} key={index} />
                        ))}
                    </ProductSlider>
                </div>

                <Link to={'/hot-products'} className={'show-more'}>
                    <a>
                        <b>
                            <ArrowRightSvg />
                        </b>
                        <p>{t('see-more-products')}</p>
                    </a>
                </Link>

            </S.HotProducts>
            <S.NewProducts>
                <div className={'row'}>
                    <p className={'title'}>{t('new-smart-products')}</p>
                    <p className={'description'}>{t('new-smart-products-description')}</p>
                </div>
                <div className={'slider'}>
                    <ProductSlider options={{ align: 'center' }}>
                        {newProducts.map((product, index) => (
                            <ProductCard product={product} key={index} />
                        ))}
                    </ProductSlider>
                </div>

                <Link to={'/new-products'} className={'show-more'}>
                    <a>
                        <b>
                            <ArrowRightSvg />
                        </b>
                        <p>{t('see-more-products')}</p>
                    </a>
                </Link>
            </S.NewProducts>
            <S.Inquiry>
                <p className={'greenia-1'}>REENIA</p>
                <p className={'greenia-2'}>GREENIA</p>

                <div className={'inquiry'}>
                    <b>
                        <InquiryLineSvg />
                    </b>

                    <div>
                        <h3>{t('home-inquiry-title')}</h3>
                        <p>{t('home-inquiry-request')}</p>
                        <p>{t('home-inquiry-description')}</p>
                        <Link to={'/'} className={'button'}>
                            <a>{t('go-to-inquiry-form')}</a>
                        </Link>
                    </div>
                </div>
            </S.Inquiry>
        </S.LayoutMaster>
    )
}

export default HomePage;