import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from '../styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {PathInterface} from "@app/interfaces/interfaces";
import {CartDomain} from "@app/domains/cart.domain";
import {CartRequest, GetCart} from "@app/api/cart.api";
import RequestOrderImage from "@app/assets/images/requestorder.png"
import {InputComponent} from "@app/components/common/input/input.component";
import {Checkbox, FormControlLabel} from "@mui/material";
import validator from "validator"
import {FileComponent} from "@app/components/common/file/file.component";
import {TextAreaComponent} from "@app/components/common/text-area/text-area.component";
import ReCAPTCHA from "react-google-recaptcha";
import {GetIpAddress} from "@app/api/ip.api";
import {SendInquiryCart} from "@app/api/inquiry.api";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const RequestOrderPage: React.FC = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "/cart", title: 'Cart'},
        {path: "#", title: 'Request for order'},
    ]
    const [ipAddress, setIpAddress] = useState<string>('')
    const [cart, setCart] = useState<CartDomain>()
    useEffect(() => {
        GetIpAddress().then(resultIp => {
            setIpAddress(resultIp.ip)
            const requestCart: CartRequest = {
                userId: 0,
                discountId: 0,
                ipAddress: resultIp.ip,
            }
            GetCart(requestCart).then(result => {
                setCart(result.data)

                if (!result.data || result.data.carts.length === 0) {
                    navigate('/cart')
                }
            })
        })
    }, [])

    const [createAccount, setCreateAccount] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const handleSetEmail = (value: any) => {
        setEmailError('')
        setEmail(value.target.value)
        if (!validator.isEmpty(value.target.value)) {
            if (!validator.isEmail(value.target.value)) {
                setEmailError(t('invalid-email-address'))
            }
        }
    }

    const [file, setFile] = useState<File | null>(null)
    const handleSelectFile = (file: any | null) => {
        setFile(file)
    }

    const [description, setDescription] = useState<string>('')
    const [descriptionError, setDescriptionError] = useState<string>('')
    const handleSetDescription = (value: any) => {
        setDescriptionError('')
        setDescription(value.target.value)
    }

    const [recaptchaValue, setRecaptchaValue] = useState<string | null>('')
    function ReCAPTCHARecaptcha(value: string | null) {
        setRecaptchaValue(value)
    }

    const [isLoading, setIsLoading] = useState(false)
    const handleSubmitForm = () => {
        if (email) {
            if (recaptchaValue !== '') {
                setIsLoading(true)
                const formData = new FormData()
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formData.append('file', file)
                formData.append('description', description)
                formData.append('email', email)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formData.append('createAccount', createAccount)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formData.append('captchaToken', recaptchaValue)

                SendInquiryCart(cart?.id ?? 0, formData).then(result => {
                    setIsLoading(false)
                    toast.success(t(result.message))

                    navigate('/cart/request-order/detail/' + result.data.uuid, {state: result.data})
                }).catch(error => {
                    setIsLoading(false)
                    toast.error(t('inquiry-request-failed'))
                })
            } else {
                toast.error(t('select-recaptcha'))
            }
        } else {
            setEmailError(t('required-email-address'))
        }
    }

    return (
        <S.LayoutMaster>
            <S.RequestOrder>
                <div className={'box-route'}>
                    <RoutePathComponent paths={routePaths}/>
                </div>
                <div className={'header'}>
                    <p>{t('send-your-request')}</p>
                    <p className={'description'}>{t('send-request-description')}</p>
                </div>
                <div className={'box'}>
                    <div className={'row'}>
                        <p>{t('enter-email-address')}</p>

                        <div className={'col'}>
                            <InputComponent error={emailError} value={email} onChange={handleSetEmail} type={'email'} placeholder={'enter-email'}/>
                            <FormControlLabel control={<Checkbox/>} label={t('create-account-email')}
                                              onChange={() => setCreateAccount(!createAccount)}/>
                        </div>
                    </div>
                    <div className={'row'}>
                        <p>{t('upload-file-request-order')}</p>

                        <div className={'col'}>
                            <FileComponent error={''} types={'*'} max={2}
                                           onChange={(file: any) => handleSelectFile(file)}/>
                        </div>
                    </div>
                    <div className={'row'}>
                        <p>{t('additional-description')}</p>
                        <div className={'col'}>
                            <TextAreaComponent error={descriptionError} value={description}
                                               onChange={handleSetDescription}/>
                        </div>
                    </div>
                    <div className={'line'}>
                        {/* @ts-ignore */}
                        <ReCAPTCHA
                            sitekey="6LcBkP4nAAAAAOoyEqyYaXUN_uvWmV0jxqHy0L_K"
                            onChange={ReCAPTCHARecaptcha}/>

                        <S.SubmitButton type="primary" htmlType="button"
                                        loading={isLoading} onClick={handleSubmitForm}>
                            <span>{t('send-request')}</span>
                        </S.SubmitButton>
                    </div>


                    <div className={'image-row'}>
                        <img src={RequestOrderImage} className={'image'}/>
                    </div>
                </div>
            </S.RequestOrder>
        </S.LayoutMaster>
    )
}

export default RequestOrderPage;