import React from 'react';
import * as S from './styles.style';
import TextField from '@mui/material/TextField';
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

export const InputComponent: React.FC<{
    error: string,
    value: string,
    onChange: any,
    type: string,
    placeholder: string,
    style?: any,
    svg?: any,
    onHandleSvg?: any,
    showPassword ?: boolean
}> = (props) => {

    const {t} = useTranslation()

    return (
        <S.Input>
            <Box
                component="div"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '100%', marginX: '0'},
                }}
            >
                <TextField
                    type={props.showPassword ? 'text' : props.type}
                    error={!!props.error}
                    value={props.value}
                    label={t(props.placeholder)}
                    defaultValue={props.value}
                    helperText={props.error}
                    onChange={props.onChange}
                    style={props.style}/>
            </Box>

            <a className={'icon'} onClick={props.onHandleSvg} style={{marginTop: props.error !== '' ? '-67px;' : '-45px'}}>
                {props.svg}
            </a>
        </S.Input>
    );
};
