import { FONT_SIZE } from '@app/assets/styles/themes/constants';
import styled from 'styled-components';

export const VideoSlider = styled.div`
  position: relative; /* Ensure arrows are positioned relative to the container */
  width: fit-content;
  height: fit-content;
  .embla {
    overflow: hidden;
    width: 100%;
  }

  .embla__container {
    display: flex;
    gap: 20px;
    user-select: none;
  }

  .embla__slide {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .video-wrapper {
    text-align: center;
  }

  .video-file {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .video-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  .embla__dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .embla__dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background: #ccc;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }

  .embla__dot.is-selected {
    background: #6a5acd;
  }

  /* Navigation Buttons */
  .embla__button {
    position: absolute;
    top: calc(50% - 22px);
    transform: translateY(-50%);
    background: transparent;
    color: var(--porpule);
    font-size: ${FONT_SIZE.xxxxl};
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: .5s;
  }

  .embla__button--prev {
    left: -40px;
  }

  .embla__button--next {
    right: -40px;
  }

  .embla__button:hover {
    color: var(--porpule-2);
  }

  .embla__button:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    .embla__button {
      display: none; /* Hide buttons on mobile */
    }
  }
`;
