import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addDeferredPrompt } from '@app/store/slices/pwaSlice';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>;
  prompt: () => Promise<void>;
}

export const usePWA = (): void => {
  const dispatch = useDispatch();
  const deferredPrompt = useRef<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      dispatch(addDeferredPrompt({ platforms: e.platforms }));
      deferredPrompt.current = e;
    };

    // Use type assertion to bypass TypeScript's event type checking
    window.addEventListener('beforeinstallprompt', handler as EventListener);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler as EventListener);
    };
  }, [dispatch]);
};
