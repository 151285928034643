import React, { useEffect, useState, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import * as S from './styles.style'; // Ensure styles are updated to include arrows
import type { EmblaOptionsType } from 'embla-carousel';

interface VideoSliderProps {
  videos: { file: string; title: string }[]; // Array of videos with file and title
  options?: EmblaOptionsType; // Optional Embla settings
}

const VideoSlider: React.FC<VideoSliderProps> = ({ videos, options }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSelect);
    onSelect(); // Ensure the index is set initially
  }, [emblaApi, onSelect]);

  return (
    <S.VideoSlider>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          {videos.map((video, index) => (
            <div className="embla__slide" key={index}>
              <div className="video-wrapper">
                {/* Video Thumbnail */}
                <video controls src={video.file} className="video-file" preload="metadata"></video>
                {/* Title */}
                <p className="video-title">{video.title}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Left Arrow */}
        <button className="embla__button embla__button--prev" onClick={scrollPrev}>
          &#x276E;
        </button>

        {/* Right Arrow */}
        <button className="embla__button embla__button--next" onClick={scrollNext}>
          &#x276F;
        </button>
      </div>

      {/* Dots for pagination */}
      <div className="embla__dots">
        {videos.map((_, index) => (
          <button
            key={index}
            className={`embla__dot ${index === selectedIndex ? 'is-selected' : ''}`}
            onClick={() => emblaApi?.scrollTo(index)}
          ></button>
        ))}
      </div>
    </S.VideoSlider>
  );
};

export default VideoSlider;
