import styled from "styled-components";
import {Layout} from "antd";
import {media} from "@app/assets/styles/themes/constants";
import {BaseButton} from "@app/components/common/BaseButton/BaseButton";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }


    .content {
      color: var(--black);
      font-family: "robotoRegular" !important;
      line-height: 24px;
      margin-top: 40px;
    }

  }
`;

export const LayoutPage = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 10px;

  .clear {
    margin: 10px;
  }

  .content {
    color: var(--black);
    font-family: "robotoRegular" !important;
    line-height: 24px;
    margin-top: 40px;

    .inputs {
        .form-group {
            display: flex;
            align-items: flex-start;

            @media only screen and ${media.lg} {
              flex-direction: column;
              gap: 10px;
              margin-bottom: 10px;

              label, .form-group-inputs {
                width: 100% !important;
              }
            }

            label {
                width: 30%;
            }

            .form-group-inputs {
                width: 70%;
                label {
                    text-wrap: nowrap;
                }
            }
        }
    }
  }


  .box-route {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }
  }

  .box {
    width: 80%;
    margin: 30px auto 0 auto;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%
      margin: 10px;
      margin-top: 40px;

      @media only screen and ${media.lg} {
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
      }

      .col-1 {
        display: table;
        width: 30%;

        p {
          color: var(--black);
          font-family: "robotoRegular" !important;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
        }

        strong {
          color: var(--black);
          font-family: "robotoMedium" !important;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
        }
      }

      .col-2 {
        display: table;
        width: 65%;

        h4 {
          color: var(--black);
          font-family: "robotoMedium" !important;
          font-size: 20px;
          font-style: normal;
          line-height: 24px;
        }
      }
    }

  }

  .header {
    width: 90%;
    padding: 10px 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10%;
    margin-top: 30px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-image: url("../../assets/images/request-order-header.png");

    h2 {
        margin-left: 30px;
        margin-top: 50px;
    }

    P {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      margin-left: 30px;
      margin-top: 20px;
      margin-bottom: 10px;
      width: 30%;
    }

    .description {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      margin-left: 30px;
      margin-top: 50px;
      margin-bottom: 10px;
      width: 30%;
    }

    @media only screen and ${media.lg} {
      p, .description {
        width: 100% !important;
      }
    }
  }



`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  cursor: pointer; 
  width: 200px;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;
export const CancelButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--white);
  padding: 15px;
  border: none;
  cursor: pointer; 
  width: 200px;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--black);
  }
`;

export const Modal = styled(Layout)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    background: var(--gray);
    border-radius: 8px;
    border: 1px solid var(--border-base-color);
    box-shadow: 0 0 20px 5px var(--border-base-color);
    
    p {
        padding: 20px 0 0;
    }

    .buttons {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
    }
`