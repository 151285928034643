import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as S from './styles.style';

export const NoCartModal = () => {
    const { t } = useTranslation();
    const navigator = useNavigate();
  return (
    <S.Modal>
        <p>{t('no-cart-items')}</p>
        <div className='buttons'>
            <S.SubmitButton onClick={() => navigator('/')}>{t('show-products')}</S.SubmitButton>
            {/* <S.CancelButton>{t('cancel')}</S.CancelButton> */}
        </div>
    </S.Modal>
  )
}
